import React from "react"
import { Link, graphql } from "gatsby"
import { Row, Col, Container } from "react-bootstrap"

import MinimalLayout from "../components/minimal-layout"
import SEO from "../components/seo"

import { logoImages } from "../constants/images"

const IndexPage = ({ data }) => (
  <MinimalLayout pageInfo={{ pageName: "index" }}>
    <SEO title="Home" keywords={[`pellets`, `pellets`]} />
    <Container fluid>
      <Row>
        <Col md={6} className="lp-half">
          <div className="lp-overlay">
            <img src={logoImages.strawWo} alt="Straw Pellets" />
            <hr />
            <h4>{data.markdownRemark.frontmatter.straw_intro}</h4>
            <Link to="/straw" className="btn btn-blue">
              Straw Pellets
            </Link>
          </div>
        </Col>
        <Col md={6} className="lp-half">
          <div className="lp-overlay">
            <img src={logoImages.woodWo} alt="Wood Pellets" />
            <hr />
            <h4>{data.markdownRemark.frontmatter.wood_intro}</h4>
            <Link to="/wood" className="btn btn-blue">
              Wood Pellets
            </Link>
          </div>
        </Col>
      </Row>
    </Container>
  </MinimalLayout>
)

export default IndexPage

export const pageQuery = graphql`
  query IndexPageQuery {
    markdownRemark(fileAbsolutePath: { regex: "/intro.md/" }) {
      frontmatter {
		straw_intro
		wood_intro
      }
    }
  }
`